import * as React from 'react';
import { useState } from 'react';
import { Col, Row } from 'react-styled-flexboxgrid';
import { graphql } from 'gatsby';
import SimpleReactLightbox from 'simple-react-lightbox';

import { FadeIn } from '~/components/animations';
import { Card } from '~/components/card';
import { LightBox } from '~/components/lightbox';
import { PageHeading } from '~/components/page-heading';
import { getMappedAllProjects, getMappedHeading } from '~/core/data-mappers';
import { Container } from '~/theme';
import { Seo } from 'gatsby-plugin-wpgraphql-seo';

const ProjectsTemplate = ({
  data: {
    pageData,
    allWpService: { services },
  },
}) => {
  const [activeIndex, setActiveIndex] = useState(null);

  const heading = getMappedHeading(pageData);
  const projects = getMappedAllProjects(services);

  return (
    <SimpleReactLightbox>
      <Seo post={pageData} />

      <PageHeading
        description={heading.description}
        title={heading.title}
        headingTag="h1"
      />

      <Container>
        <Row>
          {projects.map((project, index) => (
            <Col xl={3} lg={6} xs={12} key={project.title}>
              <FadeIn delay={index * 0.1}>
                <Card
                  title={project.title}
                  description={project.description}
                  image={project.image}
                  withSpacing={index < projects.length - 1}
                  onClick={() => setActiveIndex(index)}
                />
              </FadeIn>
            </Col>
          ))}
        </Row>
      </Container>

      <LightBox
        images={projects.map(cerf => cerf.image)}
        activeIndex={activeIndex}
        setActiveIndex={setActiveIndex}
      />
    </SimpleReactLightbox>
  );
};

export const query = graphql`
  query($language: String, $locale: String) {
    pageData: wpPage(
      slug: { eq: "projects" }
      locale: { locale: { eq: $locale } }
    ) {
      ...YoastSiteFragment
      ...PageHeadingFragment
    }
    allWpService(filter: { locale: { locale: { eq: $locale } } }) {
      services: nodes {
        serviceFields {
          projects {
            title
            description
            image {
              localFile {
                thumbnail: childImageSharp {
                  gatsbyImageData(width: 640, placeholder: BLURRED)
                }
                full: childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
                placeholder: childImageSharp {
                  gatsbyImageData(height: 4, placeholder: BLURRED)
                }
              }
            }
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...TranslationFragment
        }
      }
    }
  }
`;

export default ProjectsTemplate;
