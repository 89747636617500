import React, { useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { SRLWrapper, useLightbox } from 'simple-react-lightbox';

export const LightBox = ({ images, activeIndex, setActiveIndex }) => {
  const { openLightbox } = useLightbox();
  const isLightboxActive = activeIndex !== null;

  const callbacks = {
    onLightboxClosed: () => setActiveIndex(null),
  };

  const options = {
    showThumbnails: {
      showThumbnails: false,
    },
  };

  useEffect(() => {
    if (isLightboxActive) {
      openLightbox(activeIndex);
    }
  }, [activeIndex]);

  return (
    <SRLWrapper callbacks={callbacks} options={options}>
      {images.map(img => (
        <a
          key={img.alt}
          href={img.full.images.fallback.src}
          style={{ visibility: 'hidden', pointerEvents: 'none' }}
        >
          <GatsbyImage image={img.placeholder} alt={img.alt} />
        </a>
      ))}
    </SRLWrapper>
  );
};
