import React, { useState } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { rem } from 'polished';
import styled, { css } from 'styled-components';

import { Heading } from '~/components/heading';
import { Icon } from '~/components/icon';
import { Paragraph } from '~/components/paragraph';
import { borderRadius, colors, imageOverlay, overlayIcon } from '~/theme';

export const Card = ({
  title,
  description,
  image,
  withSpacing,
  onClick,
  isSmall,
}) => {
  const [isHovered, setHovered] = useState(false);

  return (
    <CardWrapper
      isHovered={isHovered}
      withSpacing={withSpacing}
      isSmall={isSmall}
    >
      <CardImage
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
        onClick={onClick}
        isSmall={isSmall}
      >
        <GatsbyImage
          image={image.thumbnail}
          alt={title}
          css={`
            width: 100%;
          `}
        />
        <Icon size={isSmall ? 48 : 64} name="image" />
      </CardImage>

      <CardContent>
        <CardHeading
          size={isSmall ? 'h6' : 'h4'}
          tag={isSmall ? 'h3' : 'h2'}
          isSmall={isSmall}
          spacing="sm"
          isHovered={isHovered}
        >
          <Icon name="arrow-extended" disableInlineStyles />
          <span>{title}</span>
        </CardHeading>
        <Paragraph>{description}</Paragraph>
      </CardContent>
    </CardWrapper>
  );
};

const CardWrapper = styled.div`
  margin-bottom: ${({ withSpacing }) => withSpacing && rem(48)};

  ${({ isHovered }) =>
    isHovered &&
    css`
      ${CardImage} {
        svg {
          opacity: 0;
        }
      }
    `};
`;

const CardHeading = styled(Heading)`
  position: relative;

  span {
    display: flex;
    transition: transform 0.25s ease-in-out;
  }

  svg {
    position: absolute;
    width: ${rem(64)};
    left: ${rem(-64)};
    opacity: 0;
    transition: left 0.25s ease-in-out, opacity 0.25s ease-in-out,
      transform 0.25s ease-in-out;
    transform: scale(0.2);
  }

  ${({ isHovered, isSmall }) =>
    isHovered &&
    css`
      svg {
        opacity: 1;
        left: 0;
        color: ${colors.blue500};
        transform: scale(1);
      }

      span {
        transform: translateX(${isSmall ? rem(40) : rem(72)});
        color: ${colors.blue500};
      }
    `}

  ${({ isSmall }) =>
    isSmall &&
    css`
      svg {
        width: ${rem(32)};
        top: ${rem(6)};
      }
    `}
`;

const CardContent = styled.div``;

const CardImage = styled.div`
  border-radius: ${borderRadius.md};
  overflow: hidden;
  display: flex;
  height: ${rem(320)};
  margin-bottom: ${rem(24)};

  ${imageOverlay(false)}

  svg {
    ${overlayIcon};
  }

  ${({ isSmall }) =>
    isSmall &&
    css`
      height: ${rem(120)};
      margin-bottom: ${rem(12)};
    `}
`;
